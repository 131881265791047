var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-xl-6" }, [
      _c("div", { staticClass: "table-responsive mb-0" }, [
        _c("table", { staticClass: "table" }, [
          _c("tbody", [
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [_vm._v("Référence :")]),
              _c("td", [_vm._v(_vm._s(_vm.contract.ref))])
            ]),
            _vm._m(0),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _vm._v("Date de création :")
              ]),
              _c("td", [_vm._v(_vm._s(_vm.contract.createdAt))])
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _vm._v("Date début de validité :")
              ]),
              _c("td", [_vm._v(_vm._s(_vm.contract.start_date))])
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "col-xl-6" }, [
      _c("div", { staticClass: "table-responsive mb-0" }, [
        _c("table", { staticClass: "table" }, [
          _c("tbody", [
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _vm._v("Date fin de validité :")
              ]),
              _c("td", [_vm._v(_vm._s(_vm.contract.end_date))])
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _vm._v("Date de signature :")
              ]),
              _c("td", [_vm._v(_vm._s(_vm.contract.contract_signature_date))])
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _vm._v("Date d’entrée en vigueur :")
              ]),
              _c("td", [_vm._v(_vm._s(_vm.contract.contract_effective_date))])
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [_vm._v("Status :")]),
              _c("td", [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.contract.status) }
                })
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { attrs: { scope: "row" } }, [_vm._v("Prestataire :")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }