import { render, staticRenderFns } from "./settings.vue?vue&type=template&id=598b2588&"
import script from "./settings.vue?vue&type=script&lang=js&"
export * from "./settings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bahaaeddine/public_html/hmd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('598b2588')) {
      api.createRecord('598b2588', component.options)
    } else {
      api.reload('598b2588', component.options)
    }
    module.hot.accept("./settings.vue?vue&type=template&id=598b2588&", function () {
      api.rerender('598b2588', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/base/data/contract/contract/settings.vue"
export default component.exports