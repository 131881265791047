<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import contractDetailsComponent from "./settings/contractDetailsComponent";
import penaltiesSettingsComponent from "./settings/penaltiesSettingsComponent";
import warrantiesSettingsComponent from "./settings/warrantiesSettingsComponent";
export default {
  page: {
    title: "Modifier un contrat",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, contractDetailsComponent,penaltiesSettingsComponent,warrantiesSettingsComponent },
  data() {
    return {
      title: "Modifier un contrat",
      suppliers: [],
        contract : {},
      types: [
        {
          id: "catering",
          name: "Full Catering",
        },

        {
          id: "Maintenance",
          name: "Maintenance",
        },

        {
          id: "gardening",
          name: "Espace Vert",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Contrats",
          href: "/",
        },
        {
          text: "Modifier un contrat",
          active: true,
        },
      ],
    };
  },

  mounted() {
    this.fetchContractDetails();
  },

  created() {
    this.fetchSuppliersList();
  },

  methods: {
    fetchSuppliersList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchContractDetails() {
      this.$http
        .post(
          "/contracts/contracts/getSingleContractData/" + this.$route.params.uid
        )
        .then((res) => {
          this.contract = res.data.original.data.contract;
          
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Paramètres du contrat: {{ contract.ref }}</h4>
            <p class="card-title-desc">
              
            </p>
            <b-tabs content-class="p-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Détails</span>
                </template>
                <contract-details-component :contract="contract"/>
              </b-tab>
              <b-tab v-if="canAny(['create_penalty', 'edit_penalty', 'delete_penalty', 'view_penalty', 'list_penalty'])">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Pénalités</span>
                </template>
                <penalties-settings-component :contract="contract"/>
              </b-tab>
              <b-tab v-if="canAny(['create_warranty', 'edit_warranty', 'delete_warranty', 'view_warranty', 'list_warranty'])">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Garanties</span>
                </template>
                <warranties-settings-component :contract="contract"/>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Hébergement</span>
                </template>
                
                
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Restauration</span>
                </template>
                
                
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>