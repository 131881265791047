<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required, decimal } from "vuelidate/lib/validators";

export default {
    props: {
    contract: {
      type: Object,
    },
  },
  components: { Multiselect },
  data() {
    return {
      tableData: [],
      title: "Contrats",
      submitted: false,
      contracts: [],
      showNewPenaltyModal: false,
      penaltiesTypes: [],
      newPenaltyForm: {
          uuid: this.contract.uuid,
          type: "",
          computeMethod: "",
          amnt: "",
      },
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    };
  },
  validations: {
    newPenaltyForm: {
      type: {
        required,
      },
      computeMethod: { required },
      amnt: { required, decimal },
    },
  },
  watch: {
      contract: {
          handler(val){
              if(val){
                  this.refreshContractPenaltiesTable();
                  this.newPenaltyForm.uuid = this.contract.uuid;
              }
          }
      }
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
  },
  methods: {
    _close_modal(){
        this.showNewPenaltyModal = false;
    },
    savePenalty() {
      this.submitted = true;      
      this.$v.$touch()
      if(!this.$v.$invalid){
      this.$http
        .post("/contracts/contracts/save_penalty_settings", this.newPenaltyForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this._close_modal();
              this.refreshContractPenaltiesTable();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
    deleteContractPenalty(name, penaltyId, contractRef, contractId) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la pénalité :" + name + " du contrat: "+ contractRef +"?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/contracts/delete_penalty_settings", {
                penaltyId: penaltyId,
                contractId: contractId,
            })
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  _this.refreshContractPenaltiesTable();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    openNewPenaltyModal(){
        this.fetchPenaltiesTypes()
        this.showNewPenaltyModal = true;
    },
    fetchPenaltiesTypes(){
        this.$http
            .post("/contracts/penalty_types/list")
            .then((res) => (this.penaltiesTypes = res.data.original.data))
            .catch(() => {
            // error.response.status Check status code
            })
            .finally(() => {
            //Perform action in always
            });
    },
    refreshContractPenaltiesTable() {
      this.$http
        .post("/contracts/contracts/get_penalty_settings", {
            contractUid: this.contract.uuid,
        })
        .then((res) => (this.contracts = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="row">
    <Modal v-model="showNewPenaltyModal"  title="Ajouter une pénalité">
      <form @submit.prevent="savePenalty">
        

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Type *</label>
              <multiselect
              v-model="newPenaltyForm.type"
                :searchable="true"
                track-by="id"
                label="name"
                :options="penaltiesTypes"
                :select-label="''"
                placeholder="Select"
                :allow-empty="false"
                :class="{ 'is-invalid': submitted && $v.newPenaltyForm.type.$error, }"
                >
                <template slot="singleType" slot-scope="{ type }">{{
                type.name
                }}</template>
            </multiselect>
            <div v-if="submitted && $v.newPenaltyForm.type.$error" class="invalid-feedback">
                <span v-if="!$v.newPenaltyForm.type.required">Le type est obligatoire.</span> <br>
            </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Méthode de calcule *</label>
              <select class="form-control" :class="{ 'is-invalid': submitted && $v.newPenaltyForm.computeMethod.$error, }" v-model="newPenaltyForm.computeMethod">
                  <option value="amount">Montant</option>
                  <option value="percentage">Pourcentage</option>
              </select>
              <div v-if="submitted && $v.newPenaltyForm.computeMethod.$error" class="invalid-feedback">
                <span v-if="!$v.newPenaltyForm.computeMethod.required">La Méthode de calcule est obligatoire.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Montant/pourcentage appliqué *</label>
              <input type="text" v-model="newPenaltyForm.amnt" :class="{ 'is-invalid': submitted && $v.newPenaltyForm.amnt.$error, }" class="form-control" placeholder="" />
              <div v-if="submitted && $v.newPenaltyForm.amnt.$error" class="invalid-feedback">
                <span v-if="!$v.newPenaltyForm.amnt.required">Le Montant est obligatoire.</span>
                <span v-if="!$v.newPenaltyForm.amnt.decimal">Veuillez entrer un montant valide.</span>
              </div>
            </div>
          </div>

        </div>

        <div>
          <button class="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>
    <div class="col-12">
      <a @click="refreshContractPenaltiesTable()" href="javascript:void()"
        ><h1><i class="bx bx-rotate-right" style="float: right"></i></h1
      ></a>
      <b-button @click="openNewPenaltyModal()" variant="primary"
        >Ajouter</b-button
      >
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Recherche..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <!-- Table -->
      <div class="table-responsive mb-0">
        <div class="table-responsive mb-0">
          <table class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>Désignation</th>
                <th>Methode de calcule</th>
                <th>Montant</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in contracts" :key="data.id">
                <td>
                  {{ data.name }}
                </td>
                <td>{{ data.pivot.penalty_amount_type }}</td>
                <td>
                    {{ data.pivot.amount }}    
                    <span v-if="data.pivot.penalty_amount_type == 'percentage'">%</span>
                </td>
                <td>
                  <!-- <router-link
                    v-if="$can('edit_contract')"
                    :to="{
                      name: 'base.contracts.contracts.edit',
                      params: { uid: data.uuid },
                    }"
                    class="mr-3 text-primary"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Edit"
                    ><i class="mdi mdi-pencil font-size-18"></i
                  ></router-link> -->

                  <a
                    v-if="$can('delete_contract')"
                    @click="deleteContractPenalty(data.name, data.id, contract.ref, contract.id)"
                    class="text-danger"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Delete"
                    ><i class="mdi mdi-close font-size-18"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
