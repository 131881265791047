<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required, decimal, requiredIf } from "vuelidate/lib/validators";
export default {
    props: {
    contract: {
      type: Object,
    },
  },
  components: { Multiselect },
  data() {
    return {
      tableData: [],
      title: "Contrats",
      submitted: false,
      contracts: [],
      showNewPenaltyModal: false,
      warrantiesTypes: [],
      newWarrantyForm: {
          uuid: this.contract.uuid,
          bankName: "",
          bankAgency: "",
          accNum: "",
          expDate: "",
          percentage: "",
          amnt: "",
          type: "",
          comments: "",
      },
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    };
  },
  validations: {
    newWarrantyForm: {
      type: {
        required,
      },
      bankName: {
        required: requiredIf(function() {
          return this.newWarrantyForm.type.typePlain == 'bank_warranty'
        }),
      },
      bankAgency: {
        required: requiredIf(function() {
          return this.newWarrantyForm.type.typePlain == 'bank_warranty'
        }),
      },
      accNum: {
        required: requiredIf(function() {
          return this.newWarrantyForm.type.typePlain == 'bank_warranty'
        }),
      },
      expDate: {
        required: requiredIf(function() {
          return this.newWarrantyForm.type.typePlain == 'bank_warranty'
        }),
      },
      percentage: {
        required: requiredIf(function() {
          return this.newWarrantyForm.type.typePlain == 'bank_warranty'
        }), 
        decimal
      },
    },
  },
  watch: {
      contract: {
          handler(val){
              if(val){
                  this.refreshContractPenaltiesTable();
                  this.newWarrantyForm.uuid = this.contract.uuid;
              }
          }
      }
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
  },
  methods: {
    formatAmount(val, currency = "DZD"){
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency }).format(val)
    },
    computeAmntFromPercentage(){
      this.newWarrantyForm.amnt = this.formatAmount(this.percent(this.contract.amountsPlain.planned_amount, this.newWarrantyForm.percentage));
    },
    _close_modal(){
        this.showNewPenaltyModal = false;
    },
    saveWarranty() {
      this.submitted = true;      
      this.$v.$touch()
      if(!this.$v.$invalid){
      this.$http
        .post("/contracts/contracts/save_warranty_settings", this.newWarrantyForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this._close_modal();
              this.refreshContractWarrantiesTable();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
    deleteContractPenalty(name, warrantyId, contractRef, contractId) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la garantie :" + name + " du contrat: "+ contractRef +"?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/contracts/delete_warranty_settings", {
                warrantyId: warrantyId,
                contractId: contractId,
            })
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  _this.refreshContractWarrantiesTable();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.warning(error.message);
            })
            .finally(() => {});
        }
      });
    },
    openNewWarrantyModal(){
        this.fetchWarrantiesTypes()
        this.showNewPenaltyModal = true;
    },
    fetchWarrantiesTypes(){
        this.$http
            .post("/contracts/warranty_types/list")
            .then((res) => (this.warrantiesTypes = res.data.original.data))
            .catch(() => {})
            .finally(() => {});
    },
    onChangeWarrantyType(){
      var warrantyType = this.newWarrantyForm.type.typePlain;
      if(this.contract.type != "other" && warrantyType == "withholding_warranty"){
        // this.$toast.error("Vous ne pouvez pas appliquer une garantie de retenue sur un contrat de type: "+ this.contract.type);
        // this.newWarrantyForm.type = "";
      }
    },
    refreshContractWarrantiesTable() {
      this.$http
        .post("/contracts/contracts/get_warranty_settings", {
            contractUid: this.contract.uuid,
        })
        .then((res) => (this.contracts = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="row">
    <Modal v-model="showNewPenaltyModal"  title="Ajouter une garantie">
      <form @submit.prevent="saveWarranty">
        

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Type *</label>
              <multiselect
                v-model="newWarrantyForm.type"
                :searchable="true"
                track-by="id"
                label="name"
                :options="warrantiesTypes"
                :select-label="''"
                placeholder="Select"
                @input="onChangeWarrantyType"
                :allow-empty="false"
                :class="{ 'is-invalid': submitted && $v.newWarrantyForm.type.$error, }"
                >
                <template slot="singleType" slot-scope="{ type }">{{
                type.name
                }}</template>
            </multiselect>
            <div v-if="submitted && $v.newWarrantyForm.type.$error" class="invalid-feedback">
                <span v-if="!$v.newWarrantyForm.type.required">Le type est obligatoire.</span> <br>
            </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="newWarrantyForm.type.typePlain == 'withholding_warranty'">
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Pourcentage *</label>
              <input type="text" @change="computeAmntFromPercentage()" v-model="newWarrantyForm.percentage" class="form-control" :class="{'is-invalid':submitted && $v.newWarrantyForm.percentage.$error,}">
              <div v-if="submitted && $v.newWarrantyForm.percentage.$error" class="invalid-feedback">
                <span v-if="!$v.newWarrantyForm.percentage.required">Le pourcentage est obligatoire.</span>
                <span v-if="!$v.newWarrantyForm.percentage.decimal">Veuillez entrer un pourcentage valide.</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Montant</label>
              <input type="text" v-model="newWarrantyForm.amnt" readonly disabled class="form-control" name="" id="">
            </div>
          </div>
        </div>

        <div class="row" v-if="newWarrantyForm.type.typePlain == 'bank_warranty'">
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Banque *</label>
              <input type="text" v-model="newWarrantyForm.bankName" class="form-control" :class="{'is-invalid':submitted && $v.newWarrantyForm.bankName.$error,}">
              <div v-if="submitted && $v.newWarrantyForm.bankName.$error" class="invalid-feedback">
                <span v-if="!$v.newWarrantyForm.bankName.required">La banque est obligatoire.</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Agence de *</label>
              <input type="text" v-model="newWarrantyForm.bankAgency" class="form-control" :class="{'is-invalid':submitted && $v.newWarrantyForm.bankAgency.$error,}">
              <div v-if="submitted && $v.newWarrantyForm.bankAgency.$error" class="invalid-feedback">
                <span v-if="!$v.newWarrantyForm.bankAgency.required">L'adresse de la banque est obligatoire.</span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Numéro de compte (RIB) *</label>
              <input type="text" v-model="newWarrantyForm.accNum" class="form-control" :class="{'is-invalid':submitted && $v.newWarrantyForm.accNum.$error,}">
              <div v-if="submitted && $v.newWarrantyForm.accNum.$error" class="invalid-feedback">
                <span v-if="!$v.newWarrantyForm.accNum.required">Le numéro de compte est obligatoire.</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Date d'expiration de la garantie *</label>
              <input type="date" v-model="newWarrantyForm.expDate" class="form-control" :class="{'is-invalid':submitted && $v.newWarrantyForm.expDate.$error,}">
              <div v-if="submitted && $v.newWarrantyForm.expDate.$error" class="invalid-feedback">
                <span v-if="!$v.newWarrantyForm.expDate.required">La date d'expiration est obligatoire.</span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Pourcentage *</label>
              <input type="text" @change="computeAmntFromPercentage()" v-model="newWarrantyForm.percentage" class="form-control" :class="{'is-invalid':submitted && $v.newWarrantyForm.percentage.$error,}">
              <div v-if="submitted && $v.newWarrantyForm.percentage.$error" class="invalid-feedback">
                <span v-if="!$v.newWarrantyForm.percentage.required">Le pourcentage est obligatoire.</span>
                <span v-if="!$v.newWarrantyForm.percentage.decimal">Veuillez entrer un pourcentage valide.</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="formrow-email-input">Montant</label>
              <input type="text" v-model="newWarrantyForm.amnt" readonly disabled class="form-control" name="" id="">
            </div>
          </div>
        </div>

        <div class="row">
        <div class="col-md-12">
            <div class="form-group">
              <label>Commentaires</label>
              <textarea class="form-control" rows="5" v-model="newWarrantyForm.comments"></textarea> 
            </div>
          </div>
        </div>

        <div>
          <button class="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>
    <div class="col-12">
      <a @click="refreshContractWarrantiesTable()" href="javascript:void()"
        ><h1><i class="bx bx-rotate-right" style="float: right"></i></h1
      ></a>
      <b-button @click="openNewWarrantyModal()" variant="primary"
        >Ajouter</b-button
      >
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Recherche..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <!-- Table -->
      <div class="table-responsive mb-0">
        <div class="table-responsive mb-0">
          <table class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>Type de garantie</th>
                <th>Banque</th>
                <th>Agence</th>
                <th>RIB</th>
                <th>Date d'expiration</th>
                <th>Pourcentage</th>
                <th>Montant</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in contracts" :key="data.id">
                <td>{{ data.name }} </td>
                <td>{{ data.pivot.bank_name }}</td>
                <td>{{ data.pivot.bank_agency }}</td>
                <td>{{ data.pivot.account_no }}</td>
                <td> {{ data.pivot.expiry_date }}  </td>
                <td> {{ data.pivot.warranty_percentage }} % </td>
                <td> {{ data.pivot.warranty_amnt }}  </td>
                <td>
                  

                  <a
                    v-if="$can('delete_contract')"
                    @click="deleteContractPenalty(data.name, data.id, contract.ref, contract.id)"
                    class="text-danger"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Delete"
                    ><i class="mdi mdi-close font-size-18"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
