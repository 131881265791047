var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Paramètres du contrat: " + _vm._s(_vm.contract.ref))
                ]),
                _c("p", { staticClass: "card-title-desc" }),
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "p-3 text-muted" } },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "border-0",
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-home" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Détails")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("contract-details-component", {
                          attrs: { contract: _vm.contract }
                        })
                      ],
                      1
                    ),
                    _vm.canAny([
                      "create_penalty",
                      "edit_penalty",
                      "delete_penalty",
                      "view_penalty",
                      "list_penalty"
                    ])
                      ? _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-inline-block d-sm-none"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-user"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-none d-sm-inline-block"
                                        },
                                        [_vm._v("Pénalités")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              621990160
                            )
                          },
                          [
                            _c("penalties-settings-component", {
                              attrs: { contract: _vm.contract }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.canAny([
                      "create_warranty",
                      "edit_warranty",
                      "delete_warranty",
                      "view_warranty",
                      "list_warranty"
                    ])
                      ? _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-inline-block d-sm-none"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-envelope"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-none d-sm-inline-block"
                                        },
                                        [_vm._v("Garanties")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              278685794
                            )
                          },
                          [
                            _c("warranties-settings-component", {
                              attrs: { contract: _vm.contract }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("b-tab", {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-inline-block d-sm-none" },
                                [_c("i", { staticClass: "fas fa-cog" })]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [_vm._v("Hébergement")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    }),
                    _c("b-tab", {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-inline-block d-sm-none" },
                                [_c("i", { staticClass: "fas fa-cog" })]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [_vm._v("Restauration")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }